import React, { useState, useEffect, useRef } from 'react';
import {
  //   Button,
  //   TextField,
  //   InputAdornment,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  useMediaQuery
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import orbs from '../assets/images/orbs.svg';
import NavBar from '../components/NavBar';
// import meterLeft from '../assets/images/leftMeter.svg';
// import meterRight from '../assets/images/rightMeter.svg';
import orb1 from '../assets/images/orb1.svg';
import orb2 from '../assets/images/orb2.svg';
import orb3 from '../assets/images/orb3.svg';
import pages from '../assets/images/pages.svg';
import aaron from '../assets/images/teampurple/aaron.svg';
import alice from '../assets/images/teampurple/alice.svg';
import bruce from '../assets/images/teampurple/bruce.svg';
import christoph from '../assets/images/teampurple/christoph.svg';
import devin from '../assets/images/teampurple/devin.svg';
import garrick from '../assets/images/teampurple/garrick.svg';
import james from '../assets/images/teampurple/james.svg';
import peter from '../assets/images/teampurple/peter.svg';
import rene from '../assets/images/teampurple/rene.svg';
import sapir from '../assets/images/teampurple/sapir.svg';
import thomas from '../assets/images/teampurple/thomas.svg';
import vitally from '../assets/images/teampurple/vitally.svg';
import miasma from '../assets/images/miasma.svg';
//import registeredTrademark from '../assets/images/icons/registeredTrademark.svg';
import telegram from '../assets/images/icons/telegram.svg';
import discord from '../assets/images/icons/discord.svg';
import twitter from '../assets/images/icons/twitter.svg';
import meterSection from '../assets/images/meterSection.svg';
import mobileLeftCircle from '../assets/images/mobileLeftCircle.svg';
import mobileRightCircle from '../assets/images/mobileRightCircle.svg';
import mobileLines from '../assets/images/mobileLines.svg';
const radioactivePurplesBackground =
  'linear-gradient(180deg, #131B68 24.44%, #880c7c 100%)';
const blackToBlueGradient = `linear-gradient(180deg, #191932 0%, #1C1E6E 52.74%, #2624BF 100%)`;
const gradBlueGradient = 'linear-gradient(180deg, #2624BF 0%, #1E1F7E 100%)';
// const transitionToSmokeyBlue =
//   'linear-gradient(180deg, #1E1F7E 0%, rgba(111, 122, 192, 100) 100%)';
// const smokeyBlueGradient = `linear-gradient(180deg, rgba(111, 122, 192, 100) 0%, #151A3A 117.22%)`;
// const gradBlue = '#131B68';
const blueishBlack = '#191932';
const lightPurple = '#6A6A9F';
const darkPurple = '#151A3A';

const roadmapBackground =
  'linear-gradient(180deg, rgb(47, 53, 92) 0%, #151A3A 117.22%)';
const transitionaryBackground =
  'linear-gradient(180deg, #1E1F7E 0%, #2F355C 100%)';

const LandingPage = () => {
  const [currentPage, setCurrentPage] = useState();
  const topRef = useRef();
  const overViewRef = useRef();
  const featuresRef = useRef();
  const roadmapRef = useRef();
  const teamRef = useRef();

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const refs = {
      top: topRef,
      overview: overViewRef,
      features: featuresRef,
      roadmap: roadmapRef,
      team: teamRef
    };
    if (currentPage && refs[currentPage] && refs[currentPage].current) {
      refs[currentPage].current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentPage]);
  return (
    <div className='App' style={{ overflowX: 'hidden' }}>
      <NavBar setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <div
        id='top'
        ref={topRef}
        style={{
          height: '100vh',
          background: darkPurple,
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '7rem',
          paddingBottom: '5rem'
        }}>
        <img src={orbs} alt='wise liquid nfts' style={{ width: '100vw' }} />
      </div>

      <div
        id='overview'
        ref={overViewRef}
        style={{
          color: 'white',
          background: radioactivePurplesBackground,
          minHeight: '499px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          padding: '0 3rem'
        }}>
        <Typography
          style={{ fontSize: '25px', fontWeight: 'bold', margin: '1rem 0' }}>
          Introducing
        </Typography>
        <Typography
          style={{ fontSize: '40px', fontWeight: 'bold', margin: '1rem 0' }}>
          LiquidNFTs
        </Typography>
        <Typography style={{ fontSize: '18px', margin: '1rem 0' }}>
          LiquidNFTs is a fully decentralized platform that allows the market to
          determine the value of an NFT without third-party appraisal.
          Collectors and early adopters will be a part of collaborative journey
          that unites the DeFi and NFT spaces.
        </Typography>
        <Typography
          style={{ color: 'white', fontSize: '16px', margin: '1rem 0' }}>
          LiquidNFTs is an extension of the Wise Token not-for-profit ecosystem.
        </Typography>
      </div>

      <div
        id='features'
        ref={featuresRef}
        style={{
          minHeight: '100vh',
          background: blueishBlack,
          color: 'white',
          width: '100vw',
          paddingTop: '5rem'
        }}>
        {/* <Typography
          style={{
            paddingTop: '5rem',
            marginBottom: '5rem',
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center'
          }}>
          Features
        </Typography>
        <img
          src={meterLeft}
          alt='left meter'
          style={{ width: '200px', float: 'left' }}
        />

        <span style={{ display: 'inline-block', width: '20%', float: 'left' }}>
          <div style={{ textAlign: 'center', marginBottom: '5rem' }}>
            <Typography
              style={{
                fontSize: '30px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '36px'
              }}>
              List Your NFT
            </Typography>
            <Typography
              style={{
                fontSize: '16px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '19.2px',
                padding: '.5rem 2rem'
              }}>
              Deposit NFTs into a smart contract
            </Typography>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '5rem' }}>
            <Typography
              style={{
                fontSize: '30px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '36px'
              }}>
              Get Funded
            </Typography>
            <Typography
              style={{
                fontSize: '16px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '19.2px',
                padding: '.5rem 2rem'
              }}>
              Accept contributions against the NFT value
            </Typography>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Typography
              style={{
                fontSize: '30px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '36px'
              }}>
              Enjoy your liquidity
            </Typography>
            <Typography
              style={{
                fontSize: '16px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '19.2px',
                padding: '.5rem 2rem'
              }}>
              Pay back on your own terms
            </Typography>
          </div>
        </span>

        <img
          src={meterRight}
          alt='right meter'
          style={{ width: '200px', float: 'right', display: 'inline' }}
        />

        <span
          style={{
            display: 'inline-block',
            width: '20%',
            float: 'right',
            marginRight: '2rem'
          }}>
          <div style={{ textAlign: 'center', marginBottom: '5rem' }}>
            <Typography
              style={{
                fontSize: '30px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '36px'
              }}>
              Find an NFT
            </Typography>
            <Typography
              style={{
                fontSize: '16px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '19.2px',
                padding: '.5rem 2rem'
              }}>
              Browse NFT listings with different perks and benefits
            </Typography>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '5rem' }}>
            <Typography
              style={{
                fontSize: '30px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '36px'
              }}>
              Contribute
            </Typography>
            <Typography
              style={{
                fontSize: '16px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '19.2px',
                padding: '.5rem 2rem'
              }}>
              Deposit your funds
            </Typography>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Typography
              style={{
                fontSize: '30px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '36px'
              }}>
              Collect
            </Typography>
            <Typography
              style={{
                fontSize: '16px',
                width: '275px',
                whiteSpace: 'no-wrap',
                lineHeight: '19.2px',
                padding: '.5rem 2rem'
              }}>
              Earn on your contribution
            </Typography>
          </div>
        </span> */}

        {!mediumScreen && (
          <img
            src={meterSection}
            alt='features'
            style={{ width: '100vw', transform: 'translateY(-10%)' }}
          />
        )}
        {mediumScreen && (
          <div style={{ position: 'relative', paddingTop: '9rem' }}>
            <Typography
              style={{
                fontSize: '20px',
                fontWeight: '700',
                textAlign: 'center',
                padding: '2rem 0',
                zIndex: '9000',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: '0'
              }}>
              FEATURES
            </Typography>
            <img
              src={mobileLeftCircle}
              alt='left meter'
              style={{
                marginBottom: '5rem',
                position: 'absolute',
                zIndex: '10'
              }}
            />
            <img
              src={mobileLines}
              alt='decoration'
              style={{
                position: 'absolute',
                top: '-100%',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: '0'
              }}
            />
            <img
              src={mobileRightCircle}
              alt='right meter'
              style={{ right: '0', marginTop: '28rem', position: 'absolute' }}
            />
          </div>
        )}
      </div>

      <div style={{ background: blackToBlueGradient, height: '283px' }} />

      <div
        style={{
          background: gradBlueGradient,
          height: mediumScreen ? '525px' : largeScreen ? '850px' : '1000px'
        }}>
        <img
          alt='three pages of a website'
          src={pages}
          style={{
            height: mediumScreen ? '525px' : largeScreen ? '850px' : '1000px',
            objectFit: 'contain',
            maxWidth: '175%',
            marginLeft: '50%',
            transform: 'translateX(-50%)'
          }}
        />
      </div>

      <div
        style={{
          background: transitionaryBackground,
          height: '180px'
        }}
      />

      <div
        id='roadmap'
        ref={roadmapRef}
        style={{ background: roadmapBackground, height: '736px' }}>
        <Typography
          style={{
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'white',
            marginBottom: '8rem'
          }}>
          ROADMAP
        </Typography>
        <TableContainer style={{ overflow: 'hidden' }}>
          <Table
            style={{
              padding: smallScreen
                ? '0 .25rem'
                : mediumScreen
                ? '0 1rem'
                : '0 6rem'
            }}
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}>
            <TableHead>
              <TableRow>
                <TableCell align='center' style={{ width: '33.3%' }}>
                  <Typography
                    style={{
                      color: '#FB00A1',
                      fontSize: mediumScreen ? '18px' : '25px',
                      lineHeight: '32px',
                      fontWeight: 'bold'
                    }}>
                    Step 1
                  </Typography>
                </TableCell>
                <TableCell align='center' style={{ width: '33.3%' }}>
                  <Typography
                    style={{
                      color: '#FB00A1',
                      fontSize: mediumScreen ? '18px' : '25px',
                      lineHeight: '32px',
                      fontWeight: 'bold'
                    }}>
                    Step 2
                  </Typography>
                </TableCell>
                <TableCell align='center' style={{ width: '33.3%' }}>
                  <Typography
                    style={{
                      color: '#FB00A1',
                      fontSize: mediumScreen ? '18px' : '25px',
                      lineHeight: '32px',
                      fontWeight: 'bold'
                    }}>
                    Step 3
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{
                      color: '#FB00A1',
                      fontSize: mediumScreen ? '16px' : '30px',
                      lineHeight: '28px',
                      textAlign: 'center'
                    }}>
                    LiquidNFTs
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{
                      color: '#FB00A1',
                      fontSize: mediumScreen ? '16px' : '30px',
                      lineHeight: '28px',
                      textAlign: 'center'
                    }}>
                    NFT Marketplace
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{
                      color: '#FB00A1',
                      fontSize: mediumScreen ? '16px' : '30px',
                      lineHeight: '28px',
                      textAlign: 'center'
                    }}>
                    Launchpad
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{
                      color: 'white',
                      fontSize: mediumScreen ? '15px' : '18px',
                      lineHeight: '28px',
                      textAlign: 'center'
                    }}>
                    Flagship platform that merges NFTs and DeFi
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{
                      color: 'white',
                      fontSize: mediumScreen ? '15px' : '18px',
                      lineHeight: '28px',
                      textAlign: 'center'
                    }}>
                    Data-driven service that prioritizes artists
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{
                      color: 'white',
                      fontSize: mediumScreen ? '15px' : '18px',
                      lineHeight: '28px',
                      textAlign: 'center'
                    }}>
                    A service for new community-centric NFT launches
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='center'>
                  <img
                    alt='wise logo'
                    src={orb1}
                    style={{
                      marginLeft: '50%',
                      transform: 'translateX(-50%)',
                      width: smallScreen
                        ? '90px'
                        : mediumScreen
                        ? '120px'
                        : '175px'
                    }}
                  />
                </TableCell>
                <TableCell align='center'>
                  <img
                    alt='nft marketplace'
                    src={orb2}
                    style={{
                      marginLeft: '50%',
                      transform: 'translateX(-50%)',
                      width: smallScreen
                        ? '90px'
                        : mediumScreen
                        ? '120px'
                        : '175px'
                    }}
                  />
                </TableCell>
                <TableCell align='center'>
                  <img
                    alt='rocket ship'
                    src={orb3}
                    style={{
                      marginLeft: '50%',
                      transform: 'translateX(-50%)',
                      width: smallScreen
                        ? '90px'
                        : mediumScreen
                        ? '120px'
                        : '175px'
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ minHeight: '10rem', background: blueishBlack }} />
      <div
        id='team'
        ref={teamRef}
        style={{
          background: blueishBlack,
          padding: smallScreen ? '3rem 0' : '3rem 1rem',
          paddingTop: '0'
        }}>
        <Typography
          style={{
            fontWeight: 'bold',
            paddingTop: '4rem',
            marginBottom: '4rem',
            fontSize: '30px',
            lineHeight: '16.5px',
            color: 'white',
            textAlign: 'center'
          }}>
          MEET THE TEAM
        </Typography>
        <TableContainer
          style={{ marginLeft: '50%', transform: 'translateX(-50%)' }}>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}>
            <TableBody>
              <TableRow>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/peter-girr-7415ab67/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={peter}
                      alt='peter'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/ildiscgolfer/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={aaron}
                      alt='aaron'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/vitallymarinchenko'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={vitally}
                      alt='vitally'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/christoph-krpoun-2762a4175'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={christoph}
                      alt='christoph'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/devinmarty'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={devin}
                      alt='devin'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <img
                    src={rene}
                    alt='rene'
                    style={{
                      width: smallScreen
                        ? '60px'
                        : mediumScreen
                        ? '110px'
                        : '185px'
                    }}
                  />
                </TableCell>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/garrickhw'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={garrick}
                      alt='garrick'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/tingyuan-alice-chen-294155118'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={alice}
                      alt='alice'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/sunmandev/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={james}
                      alt='james'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/thomasfoydel/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={thomas}
                      alt='thomas'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/sapir-m/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={sapir}
                      alt='sapir'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
                <TableCell
                  align='center'
                  padding={smallScreen ? 'none' : 'default'}>
                  <a
                    href='https://www.linkedin.com/in/bruce-darcy-82a55a162/'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                      src={bruce}
                      alt='bruce'
                      style={{
                        width: smallScreen
                          ? '60px'
                          : mediumScreen
                          ? '110px'
                          : '185px'
                      }}
                    />
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div
        style={{
          minHeight: '100vh',
          backgroundImage: `url(${miasma})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}>
        <Typography
          style={{
            paddingTop: '9rem',
            fontWeight: 'bold',
            fontSize: '30px',
            textAlign: 'center',
            color: 'white'
          }}>
          Subscribe To Get Notified
        </Typography>
        <Typography
          style={{
            marginTop: '1rem',
            marginBottom: '5rem',
            fontSize: '18px',
            textAlign: 'center',
            color: 'white',
            padding: '0 1rem'
          }}>
          We will let you know as soon as LiquidNFTs is up and running!
        </Typography>
        <div
          style={{
            width: '50%',
            minWidth: '320px',
            marginLeft: '50%',
            transform: 'translate(-50%)'
          }}>
          {/* <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Button
                    style={{
                      width: '134px',
                      textTransform: 'none',
                      background: lightPurple,
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '16px'
                    }}>
                    Notify me
                  </Button>
                </InputAdornment>
              )
            }}
            fullWidth
            placeholder='Enter your email'
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '8px',
              marginLeft: '50%',
              transform: 'translateX(-50%)',
              border: '1px solid #A0AEC0',
              background: 'white',
              overflow: 'hidden'
            }}
          /> */}

          <div
            id='mc_embed_signup'
            style={{ border: '2px solid #A0AEC0', borderRadius: '8px' }}>
            <form
              action='https://liquidnfts.us5.list-manage.com/subscribe/post?u=61e26c9aac8f35828dccb5970&amp;id=5cf9b29989'
              method='post'
              id='mc-embedded-subscribe-form'
              name='mc-embedded-subscribe-form'
              className='validate'
              target='_blank'
              style={{ padding: '7px' }}
              noValidate>
              <div
                id='mc_embed_signup_scroll'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <input
                  type='email'
                  name='EMAIL'
                  className='email'
                  id='mce-EMAIL'
                  placeholder='Enter your email'
                  required
                  style={{ border: 'none' }}
                />
                {/* Bot trap input, do not remove: */}
                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden='true'>
                  <input
                    type='text'
                    name='b_61e26c9aac8f35828dccb5970_5cf9b29989'
                    tabIndex='-1'
                  />
                </div>
                <div className='clear'>
                  <button
                    type='submit'
                    name='subscribe'
                    id='mc-embedded-subscribe'
                    className='button'
                    style={{
                      width: '134px',
                      textTransform: 'none',
                      background: lightPurple,
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      height: '2.5rem',
                      borderRadius: '5px'
                    }}>
                    Notify me
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Typography
          style={{
            marginTop: '8rem',
            marginBottom: '3rem',
            textAlign: 'center',
            fontWeight: 'bold',
            lineHeight: '15px',
            fontSize: '24px',
            color: 'white'
          }}>
          Stay Connected
        </Typography>

        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'space-evenly',
            width: '50%',
            minWidth: '250px',
            marginLeft: '50%',
            marginBottom: '1rem',
            transform: 'translateX(-50%)'
          }}>
          <a
            href='https://t.me/WiseToken'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none'
            }}>
            <img
              src={telegram}
              alt='telegram'
              style={{ paddingBottom: '.2rem', marginRight: '.5rem' }}
            />
            <Typography
              style={{
                fontSize: '14px',
                color: 'white',
                fontWeight: 'bold',
                marginRight: '1rem'
              }}>
              TELEGRAM
            </Typography>
          </a>
          <a
            href='https://discord.gg/keJUb4W'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none'
            }}>
            <img
              src={discord}
              alt='discord'
              style={{ paddingBottom: '.3rem', marginRight: '.5rem' }}
            />
            <Typography
              style={{
                fontSize: '14px',
                color: 'white',
                fontWeight: 'bold',
                marginRight: '1rem'
              }}>
              DISCORD
            </Typography>
          </a>
          <a
            href='https://twitter.com/wise_token'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none'
            }}>
            <img
              src={twitter}
              alt='twitter'
              style={{ paddingBottom: '.2rem', marginRight: '.5rem' }}
            />
            <Typography
              style={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
              TWITTER
            </Typography>
          </a>
        </div>
      </div>

      <div
        style={{
          background: blueishBlack,
          height: '32px',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography style={{ color: '#5A5A89' }}>
          Copyright &copy; 2021 &mdash; WiseSoft, LLC
        </Typography>
      </div>
    </div>
  );
};

export default LandingPage;
